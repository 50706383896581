<template>
  <div>
    <b-button
      v-if="!isBusy"
      :disabled="disabled"
      size="sm"
      class="mr-1"
      variant="success"
      @click="updateFunction()"
    >
      <span>{{ $t("global.update") }}</span>
    </b-button>
    <b-button v-if="isBusy" size="sm" variant="success" disabled>
      <b-spinner small type="grow"></b-spinner>
      {{ $t("global.loading") }}
    </b-button>
  </div>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    updateFunction() {
      this.$emit("updateFunction");
    },
  },
};
</script>

<style></style>
