<template>
  <b-card
    :header="$i18n.t('navigation.products-products')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <ProductPopup :data="modalProduct" :is-approved="true" />
    <ModalProductMobileView :product="mobileViewProduct" />

    <FiltersAndSearch
      :id.sync="id"
      :searchKey.sync="searchKey"
      :primaryStatus.sync="status"
      :primaryStatusFields="productStatusList"
      :activeOnly.sync="activeOnly"
      :startDate.sync="startDate"
      :endDate.sync="endDate"
      :priceSort.sync="priceSort"
      :sortOrder.sync="sortOrder"
    />
    <!-- export -->
    <ExportFromTo :exportHandler="exportProductsHandler" class="mb-2" />
    <!-- end export -->
    <b-table
      ref="refProductsListTable"
      sticky-header
      bordered
      hover
      responsive
      class="shadow-sm rounded products-table"
      thead-tr-class="order-main-thead"
      :items="productsList"
      :fields="fields"
      primary-key="id"
      :busy="isTableBusy"
      show-empty
      empty-text="No matching records found"
      small
      @row-clicked="onRowClicked"
    >
      <template #cell(nameGeo)="data">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <span>{{ data.item.nameGeo }}</span>
            <span>{{ data.item.nameEng }}</span>
          </div>
          <div
            v-if="data.item.serviceType === 4"
            class="ml-auto border border-info rounded-pill d-flex align-items-center"
          >
            <span class="text-info" style="font-size: 11px; padding: 0 0.5rem">
              moodie
            </span>
          </div>
          <div
              v-if="data.item.status === 4"
              class="ml-auto border border-warning rounded-pill d-flex align-items-center"
          >
            <span class="text-warning" style="font-size: 11px; padding: 0 0.5rem">
              edited
            </span>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button v-if="areActionsAllowed"
          class="btn-icon"
          variant="flat-success"
          @click="onRowClicked(data.item)"
        >
          <feather-icon size="16" icon="EditIcon" />
        </b-button>
        <b-button
          class="btn-icon"
          variant="flat-success"
          @click="getMobileView(data.item)"
        >
          <feather-icon size="16" icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>

    <Pagination
      :fetch-data="fetchProducts"
      :total-rows="totalRows"
      :take.sync="take"
      :skip.sync="skip"
    />
  </b-card>
</template>

<script>
import { BTable, BCard, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalProductMobileView from "@/views/products/components/ModalProductMobileView.vue";
import isAllowed from "@core/utils/isAllowed";
import ProductPopup from "./ProductPopup.vue";
import Pagination from "../components/Pagination/Pagination.vue";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    BTable,
    BCard,
    BButton,
    ModalProductMobileView,
    Pagination,
    ProductPopup,
    ExportFromTo,
    FiltersAndSearch,
  },
  data() {
    return {
      take: 20,
      skip: 0,
      totalRows: 0,
      to: null,
      from: null,
      productsList: [],
      searchQuery: "",
      modalProduct: null,
      mobileViewProduct: null,
      fields: [
        {
          label: "Actions",
          key: "actions",
        },
        {
          label: "ID",
          key: "id",
        },
        {
          label: "Display ID",
          key: "emotyCustomId",
        },
        {
          label: "Name",
          key: "nameGeo",
          tdClass: "nameColumn",
        },
        {
          key: "price",
          label: "Price",
          sortable: false,
          formatter: (value, key, item) => `${item?.price?.toFixed(2)} ₾`,
        },
        {
          key: "configurationCount",
          label: "Count",
          formatter: (value, key, item) => item?.productConfigurations.length ? item?.configurationCount : item?.count,
        },
        {
          key: "emotyPercentCommissionValue",
          label: "Commission",
        },
        {
          key: "markUpPercentCommissionValue",
          label: "Markup",
        },
        {
          label: "Created at",
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.end_at"),
          key: "activeTo",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.hiddenUntilAt"),
          key: "hiddenUntilAt",
        },
        {
          label: "Vendor",
          key: "vendor.brandNameGeo",
          sortable: false,
        },
        {
          label: "Category",
          key: "productCategory",
          formatter: (value, key, item) => `${item[key]?.productCategoryNameGeo} / ${item[key]?.productCategoryNameGeo}`,
          sortable: false,
        },
        {
          label: "Sub Category",
          key: "productSubCategory",
          formatter: (value, key, item) => `${item[key]?.productSubCategoryNameGeo} / ${item[key]?.productSubCategoryNameEng}`,
          sortable: false,
        },
        {
          label: "Generic Category",
          key: "productGenericCategory",
          formatter: (value, key, item) => `${item[key]?.productGenericCategoryNameGeo} / ${item[key]?.productGenericCategoryNameEng}`,
          sortable: false,
        },
        {
          label: "SKU",
          key: "upcOrSku",
        },
      ],
      sortOrder: 2,
      id: "",
      searchKey: "",
      activeOnly: true,
      status: "",
      productStatusList: {
        2: "Rejected",
        3: "Approved",
        4: "Edited",
      },
      endDate: "",
      startDate: "",
      priceSort: false,
    };
  },

  computed: {
    ...mapState("products", ["products", "product", "isTableBusy"]),
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return !isAllowed(["Analyst"], roles);
    },
  },
  watch: {
    products(newVal) {
      this.productsList = newVal.products;
      this.totalRows = newVal.count;
    },
    searchKey() {
      this.fetchProducts();
    },
    activeOnly() {
      this.fetchProducts();
    },
    status() {
      this.fetchProducts();
    },
    id() {
      this.fetchProducts();
    },
    startDate() {
      this.fetchProducts();
    },
    endDate() {
      this.fetchProducts();
    },
    priceSort() {
      this.fetchProducts();
    },
    take() {
      this.fetchProducts();
    },
    sortOrder() {
      this.fetchProducts();
    },
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    ...mapActions("products", [
      "getProducts",
      "getProductById",
      "exportProducts",
    ]),
    async fetchProducts() {
      try {
        await this.getProducts({
          productType: "products",
          skip: this.skip,
          take: this.take,
          status: this.status,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          id: this.id,
          startDate: this.startDate,
          endDate: this.endDate,
          priceSort: this.priceSort,
        });
        this.totalRows = this.products?.count;
        this.productsList = this.products?.products;
      } catch (error) {
        console.log(error);
      }
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    async exportProductsHandler(from, to) {
      const payload = { from, to };

      try {
        const response = await this.exportProducts(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Products-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    async onRowClicked(item) {
      console.log("onRowClicked", item);
      await this.getProductById({ productType: "products", id: item.id });
      console.log(this.product);
      this.modalProduct = this.product;
      this.$bvModal.show("product-show-modal");
    },
    async getMobileView(item) {
      await this.getProductById({ productType: "products", id: item.id });
      console.log("prod mobile view", this.product);
      this.mobileViewProduct = this.product;
      this.$bvModal.show("product-mobile-modal");
    },
  },
};
</script>

<style>
.filters .form-group {
  max-width: 300px;
}
.products-table th,
.products-table td {
  white-space: nowrap;
}
</style>
