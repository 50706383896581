<template>
  <b-modal
    id="product-show-modal"
    ref="product-show-modal"
    scrollable
    hide-footer
    size="xl"
    :title="
      productData
        ? `${productData.nameGeo} / ${productData.nameEng}`
        : 'Product Details'
    "
    @shown="getData"
    @hide="hideModal"
  >
    <b-modal id="main-image-modal" size="lg" ok-only>
      <img
        v-if="tempImageUrl"
        :src="tempImageUrl"
        alt="main image"
        class="modal-image mx-auto"
      />
    </b-modal>
    <div v-if="productData">
      <b-col>
        <div v-if="productData.mainImage">
          <label for="">Main image</label>
          <div>
            <img
              :src="productData.mainImage.url"
              class="popup-gallery-img cursor-pointer"
              @click="handleMainImageModal(productData.mainImage.url)"
            />
          </div>
        </div>
        <div v-if="productData.gallery.length">
          <label for="">Gallery images</label>
          <div class="d-flex flex-wrap">
            <div v-for="img in productData.gallery" :key="img.id">
              <img
                :src="img.url"
                class="popup-gallery-img cursor-pointer"
                @click="handleMainImageModal(img.url)"
              />
            </div>
          </div>
        </div>

        <h2 class="d-flex justify-content-center mt-1">About Product:</h2>
      </b-col>
      <b-card>
        <h6 class="mb-2">Basic Information</h6>
        <b-row>
          <b-col md="3">
            <label>Name Geo:</label>
            <p>{{ productData.nameGeo }}</p>
          </b-col>
          <b-col md="3">
            <label>Name Eng:</label>
            <p>{{ productData.nameEng }}</p>
          </b-col>
          <b-col v-if="productData.upcOrSku" md="3">
            <label>SKUT:</label>
            <p>{{ productData.upcOrSku }}</p>
          </b-col>
          <b-col sm="3">
            <label>Service type:</label>
            <p>{{ serviceTypeList[productData.serviceType] }}</p>
          </b-col>
          <b-col sm="3">
            <label>Gift type:</label>
            <p>
              {{ giftTypeList[productData.productGenericCategory.giftType] }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>Category:</label>
            <p>
              {{
                `${productData.productCategory.productCategoryNameGeo} /
									${productData.productCategory.productCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>Sub Category:</label>
            <p>
              {{
                `${productData.productSubCategory.productSubCategoryNameGeo} /
									${productData.productSubCategory.productSubCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>Generic Category:</label>
            <p>
              {{
                `${productData.productGenericCategory.productGenericCategoryNameGeo} /
									${productData.productGenericCategory.productGenericCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col v-if="productData.branch" sm="3">
            <b-form-group label="Branch" label-for="branches">
              <p>
                {{
                  `${productData.branch.nameEng} / ${productData.branch.nameGeo}`
                }}
              </p>
            </b-form-group>
          </b-col>
          <b-col v-if="productData.vendor && productData.vendor.brandNameGeo" sm="3">
            <b-form-group label="Brand Name" label-for="brandname">
              <p>
                {{ productData.vendor.brandNameGeo }} /
                {{ productData.vendor.brandNameEng }}
              </p>
            </b-form-group>
          </b-col>
          <b-col v-if="productData.vendor && productData.vendor.id" sm="3">
            <b-form-group label="Vendor ID" label-for="vendorId">
              <p>
                {{ productData.vendor.id }}
              </p>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6>Characteristics</h6>
            <b-row>
              <b-col sm="3">
                <label>Count:</label>
                <p>{{ productData.count }}</p>
              </b-col>
              <b-col sm="3">
                <label>Price:</label>
                <p>{{ productData.price }}</p>
              </b-col>
              <b-col v-if="productData.sale" md="3">
                <label>Sale:</label>
                <p v-if="productData.sale.type === 1">
                  {{ productData.sale.fixed }} Fixed
                </p>
                <p v-else>{{ productData.sale.percent }} %</p>
              </b-col>
              <b-col v-if="productData.saleUntilAt" md="3">
                <label>Sale unit at:</label>
                <p>
                  {{
                    productData.saleUntilAt
                        ? productData.saleUntilAt.slice(0, 16)
                        : ""
                  }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <h6>Characteristics</h6>
            <b-row>
              <b-col sm="3">
                <label>Min age:</label>
                <p>{{ productData.minAge }}</p>
              </b-col>
              <b-col sm="3">
                <label>Max age:</label>
                <p>{{ productData.maxAge }}</p>
              </b-col>
              <b-col sm="3">
                <label>Target Gender:</label>
                <p>{{ genderLabel[productData.productTargetGender] }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Commission type" label-for="i-name-eng">
              <b-form-select
                v-model="productData.commissionFeeType"
                :options="commissionTypes"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Emoty Commission Fee %" label-for="i-name-eng">
              <b-form-input
                id="i-eng"
                v-model="productData.emotyPercentCommissionValue"
                type="number"
                placeholder="Fee here..."
                :disabled="productData.commissionFeeType === 2"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Markup Fee %" label-for="i-name-eng">
              <b-form-input
                id="i-eng"
                v-model="productData.markUpPercentCommissionValue"
                type="number"
                placeholder="Fee here..."
                :disabled="productData.commissionFeeType === 1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="productData.serviceType !== 4" class="mb-2">
        <h6 class="mb-2">Location</h6>
        <b-row>
          <b-col md="4">
            <label>Location:</label>
            <p v-if="productData.locationGeo || productData.locationEng">
              {{ `${productData.locationGeo} /  ${productData.locationEng}` }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>City:</label>
            <p>
              {{
                `${productData.city.cityNameGeo} / ${productData.city.cityNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>Location free:</label>
            <p>
              {{ productData.isLocationFree ? "Yes" : "No" }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h6 class="mb-2">Product Description</h6>
        <b-row>
          <b-col md="6">
            <label>Description Geo:</label>
            <div v-html="productData.descriptionGeo && productData.descriptionGeo.split('\r').join('<br />')"/>
          </b-col>
          <b-col md="6">
            <label>Description Eng:</label>
            <div v-html="productData.descriptionEng && productData.descriptionEng.split('\r').join('<br />')"/>
          </b-col>
          <b-col md="6">
            <label>Terms of use Geo:</label>
            <p>{{ productData.useTermsGeo }}</p>
          </b-col>
          <b-col md="6">
            <label>Terms of use Eng:</label>
            <p>{{ productData.useTermsEng }}</p>
          </b-col>
          <b-col md="3">
            <label>Active From:</label>
            <p>
              {{
                productData.activeFrom
                  ? productData.activeFrom.slice(0, 16)
                  : ""
              }}
            </p>
          </b-col>
          <b-col v-if="productData.activeTo" md="3">
            <label>Active To:</label>
            <p>
              {{
                productData.activeTo ? productData.activeTo.slice(0, 16) : ""
              }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <app-collapse>
        <app-collapse-item
          v-if="productData.productAttributes.length"
          title="Attributes"
          class="mb-2"
        >
          <div
            v-for="attribute in productData.productAttributes"
            :key="attribute.id"
            class="p-1 border my-1"
          >
            <p class="mb-0">
              {{ attribute.nameGeo }} / {{ attribute.nameEng }}
            </p>
            <small class="mb-0">
              {{ attribute.value.valueEng }} /
              {{ attribute.value.valueGeo }}
            </small>
          </div>
        </app-collapse-item>
        <app-collapse-item
          v-if="productData.attributes"
          title="Attributes"
          class="mb-2"
        >
          <div
            v-for="attribute in productData.attributes"
            :key="attribute.id"
            class="p-1 border my-1"
          >
            <p class="mb-0">
              {{ attribute.nameGeo }} / {{ attribute.nameEng }}
            </p>
            <small class="mb-0">
              {{ attribute.value.valueEng }} /
              {{ attribute.value.valueGeo }}
            </small>
          </div>
        </app-collapse-item>
        <app-collapse-item
          v-if="productData.productConfigurations.length"
          title="Product Configuration"
          class="mb-2"
        >
          <app-collapse
            v-for="item in productData.productConfigurations"
            :key="item.productSubCategoryId"
            type="margin"
            accordion
          >
            <app-collapse-item :title="item.colorCode">
              <div>
                <img
                  :src="item.mainImage && item.mainImage.url"
                  class="popup-gallery-img cursor-pointer"
                  @click="handleMainImageModal(item.mainImage && item.mainImage.url)"
                />
              </div>
              <div v-if="item.gallery.length">
                <label for="">Gallery images</label>
                <div class="d-flex flex-wrap">
                  <div v-for="img in item.gallery" :key="img.id">
                    <img
                      :src="img.url"
                      class="popup-gallery-img cursor-pointer"
                      @click="handleMainImageModal(img.url)"
                    />
                  </div>
                </div>
              </div>
              <b-form-group label-for="i-name-eng">
                <div class="mb-1">
                  <label>Description Geo:</label>
                  <p>{{ productData.descriptionGeo }}</p>
                </div>
                <div v-for="option in item.options" :key="option.id">
                  <div>
                    <label>Option:</label>
                    <p>
                      {{ `${option.sizeOptionGeo} / ${option.sizeOptionEng}` }}
                    </p>
                  </div>
                  <div class="d-flex mb-1">
                    <div class="mr-2 border rounded px-2">
                      <label>UPC/SKU:</label>
                      <p>{{ option.upcOrSku }}</p>
                    </div>
                    <div class="mr-2 border rounded px-2">
                      <label>Quantity:</label>
                      <p>{{ option.count }}</p>
                    </div>
                  </div>
                  <hr />
                </div>
              </b-form-group>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
      </app-collapse>
      <b-card
        v-if="isApproved && productData.algorithmDescriptors.length"
        title="Algorithm Descriptors"
      >
        <validation-observer ref="algorithmDescriptorsRef" class="descriptorsWrapper">
          <b-form-group
              v-for="algorithm in productData.algorithmDescriptors"
              :key="algorithm.id"
              v-model.number="algorithm.value"
              :label="algorithm.descriptorGeo"
              class="algorithm-desc-form mr-1 mt-auto mb-2"
              type="number"
          >
              <div class="d-flex align-items-center">
                <b-form-checkbox
                    v-model="algorithm.includeInMatching"
                    class="mr-05"
                    @input="onApprovedInputUpdate(algorithm)"
                />
                <validation-provider
                    #default="{ errors }"
                    class="w-100"
                    :name="algorithm.descriptorGeo"
                    :rules="algorithm.includeInMatching ? 'required|positive|integer' : ''"
                >
                  <b-form-input
                      v-model.number="algorithm.value"
                      :disabled="!algorithm.includeInMatching"
                      :state="algorithm.includeInMatching && errors.length > 0 ? false : null"
                      type="text"
                  />
                  <small v-if="algorithm.includeInMatching" class="error-msg text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
          </b-form-group>
        </validation-observer>
        <div
          v-if="isApproved && areActionsAllowed"
          class="pb-1 d-flex align-items-center"
        >
          <div class="mr-1">
            <b-form-select
              v-if="productData.status === 4"
              v-model="selectedStatus"
              :options="productStatusList"
            />
          </div>
          <update-button
            v-if="productData.status != 2"
            :isBusy="isTableBusy"
            @updateFunction="approvedProductEdit"
          />
        </div>
        <!-- <b-row>
          <b-col v-for="alg in productData.algorithmDescriptors" :key="alg.id" md="3">
            <label>{{ alg.descriptorGeo }} / {{ alg.descriptorEng }}</label>
            <p>{{ parseFloat(alg.value).toFixed(2) }}</p>
          </b-col>
        </b-row> -->
      </b-card>
      <b-card v-if="!isApproved" title="Algorithm Descriptors">
        <validation-observer ref="algorithmDescriptorsRef" class="descriptorsWrapper">
          <b-form-group
              v-for="algorithm in algorithmDescriptorsData"
              :key="algorithm.id"
              class="algorithm-desc-form mr-1 mt-auto mb-2"
              :label="algorithm.descriptorGeo"
            >
              <div
                  class="d-flex align-items-center items-center items-align-center"
              >
                <b-form-checkbox
                    v-model="algorithm.includeInMatching"
                    class="mr-05"
                    @input="onInputUpdate(algorithm)"
                />
                <validation-provider
                    #default="{ errors }"
                    class="w-100"
                    :name="algorithm.descriptorGeo"
                    :rules="algorithm.includeInMatching ? 'required|positive|integer' : ''"
                >
                  <b-form-input
                      v-model.number="algorithm.value"
                      :disabled="!algorithm.includeInMatching"
                      :state="algorithm.includeInMatching && errors.length > 0 ? false : null"
                      type="text"
                  />
                  <small v-if="algorithm.includeInMatching" class="error-msg text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group>
        </validation-observer>
        <div v-if="isApproved && areActionsAllowed" class="pb-1">
          <update-button
            :disabled="!algorithmDescriptorsValid"
            :isBusy="isTableBusy"
            @updateFunction="approvedProductEdit"
          />
        </div>
        <span v-if="!isApproved && areActionsAllowed">
          <b-button
            size="sm"
            class="mr-1"
            variant="success"
            @click="acceptProduct()"
          >
            <span>Accept</span>
          </b-button>
          <b-button size="sm" variant="danger" @click="rejectProduct()">
            <span>Reject</span>
          </b-button>
        </span>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";

import {
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BFormCheckbox,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, positive, integer } from "@validations";
import UpdateButton from "../components/UpdateButton/UpdateButton.vue";

export default {
  components: {
    BButton,
    BCol,
    BCard,
    BFormSelect,
    BRow,
    AppCollapse,
    BFormInput,
    BFormGroup,
    UpdateButton,
    AppCollapseItem,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    getProducts: {
      type: Function,
      default: () => true,
    },
    isApproved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      positive,
      integer,
      productData: null,
      tempImageUrl: null,
      algorithmDescriptorsData: null,
      selectedStatus: null,
      algorithmDescriptorsValid: true,
    };
  },
  computed: {
    ...mapState("products", [
      "products",
      "algorithmDescriptors",
      "isTableBusy",
    ]),
    areActionsAllowed() {
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor", "Category Manager"], roles);
    },
    serviceTypeList() {
      return {
        1: "Experiential",
        2: "Material",
        3: "Digital",
        4: "Card",
      };
    },
    genderLabel() {
      return {
        1: "Male",
        2: "Female",
        3: "Both",
      };
    },
    giftTypeList() {
      return {
        1: "Both",
        2: "Daily",
        3: "Holiday",
      };
    },
    commissionTypes() {
      return [
        {
          value: 0,
          text: "Both",
        },
        {
          value: 1,
          text: "Emoty",
        },
        {
          value: 2,
          text: "Markup",
        },
      ];
    },
    productStatusList() {
      return {
        2: "Rejected",
        3: "Approved",
      };
    },
  },
  watch: {
    data(newVal) {
      this.productData = newVal;
    },
    algorithmDescriptors(newValue) {
      this.algorithmDescriptorsData = newValue.map(el => {
        // eslint-disable-next-line no-param-reassign
        el.value = el.value ? el.value : "0"
        return el
      });
    },
    'productData.algorithmDescriptors': {
      handler() {
        this.validateDescriptors();
      },
      deep: true
    },
    algorithmDescriptorsData: {
      handler() {
        this.validateDescriptors();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("products", [
      "setProductStatus",
      "getAlgorithmDescriptors",
      "setApprovedProductStatus",
    ]),
    async onApprovedInputUpdate(algorithm) {
      await this.validateDescriptors();
      const index = this.productData.algorithmDescriptors.filter(el => algorithm.id === el.id)[0]
      index.value = typeof algorithm.value === 'string' ? parseInt(algorithm.value, 10) : algorithm.value;
    },
    async onInputUpdate() {
      await this.validateDescriptors();
    },
    validateDescriptors() {
      this.$nextTick(() => {
        if (this.$refs.algorithmDescriptorsRef) {
          this.$refs.algorithmDescriptorsRef.validate().then((res) => {
            this.algorithmDescriptorsValid = res
          })
        }
      });
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    handleMainImageModal(imageUrl) {
      this.tempImageUrl = imageUrl;
      this.$bvModal.show("main-image-modal");
    },
    async getData() {
      await this.getAlgorithmDescriptors();
      this.algorithmDescriptorsData = this.algorithmDescriptors.map(el => {
        // eslint-disable-next-line no-param-reassign
        el.value = "0"
        return el
      });
      // this.algorithmDescriptorsData = this.algorithmDescriptorsData.map((el) => { return { id: el.id, value: parseInt(el.value, 10), includeInMatching: el.includeInMatching } }
    },
    hideModal() {
      this.$refs["product-show-modal"].hide();
    },
    swalConfirmation(type = "reject") {
      return Swal.fire({
        title: "Are you sure?",
        text: `You will ${type} this product!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${type}!`,
      });
    },
    async acceptProduct() {
      if (!this.algorithmDescriptorsData) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid inputs",
        });
      } else {
        const result = await this.swalConfirmation("approve");
        if (result.isConfirmed) {
          try {
            const algorithmDescriptorModified = this.algorithmDescriptorsData.map(
                (el) => {
                  const data = {
                    id: el.id,
                    value: parseInt(el.value, 10),
                  }
                  if (el.includeInMatching !== null) data.includeInMatching = el.includeInMatching
                  return data
                });
            await this.setProductStatus({
              id: this.productData.id,
              data: {
                status: 3,
                descriptors: algorithmDescriptorModified,
                commissionFeeType: this.productData.commissionFeeType,
                emotyPercentCommissionValue:
                  this.productData.emotyPercentCommissionValue,
                markUpPercentCommissionValue:
                  this.productData.markUpPercentCommissionValue,
              },
            });
            this.getProducts();
            this.hideModal();
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    async rejectProduct() {
      const result = await this.swalConfirmation();
      if (result.isConfirmed) {
        try {
          await this.setProductStatus({
            id: this.productData.id,
            data: {
              status: 2,
            },
          });
          this.$bvModal.hide("product-show-modal");

          this.getProducts();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async approvedProductEdit() {
      if (this.productData.status === 4 && this.selectedStatus === null) return;
      try {
        const algorithmDescriptorModified = this.productData.algorithmDescriptors.map(
            (el) => {
              const data = {
                id: el.id,
                value: parseInt(el.value, 10),
              }
              if (el.includeInMatching !== null) data.includeInMatching = el.includeInMatching
              console.log("data", data)
              return data
            });
        await this.setApprovedProductStatus({
          id: this.productData.id,
          data: {
            status: this.selectedStatus ? this.selectedStatus : 3,
            descriptors: algorithmDescriptorModified,
            commissionFeeType: this.productData.commissionFeeType,
            emotyPercentCommissionValue:
              this.productData.emotyPercentCommissionValue,
            markUpPercentCommissionValue:
              this.productData.markUpPercentCommissionValue,
          },
        });
        this.getProducts();
        this.toast("Product successfully edited", "success");
      } catch (error) {
        console.log(error);
        this.toast("Something went wrong", "error");
      }
    },
  },
};
</script>

<style lang="scss">
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 47%;
  }
}

.feather-24 {
  width: 16px;
  height: 16px;
}

.image-wrapper {
  margin: 0 auto;
  width: auto !important;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }
}

.select-wrapper {
  width: 100%;
}

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.descriptorsWrapper {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  // justify-content: space-between;
}

.popup-gallery-img {
  max-height: 125px;
  margin: 0.5rem;
  border-radius: 15px;
}
.modal-body {
  display: flex;
}
.modal-image {
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  max-height: 500px;
}

.algorithm-desc-form {
  position: relative;
  .error-msg {
    position: absolute;
  }
}

</style>
